import { Box, PaletteMode } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import mui from '../mui';

interface ThemeProviderProps {
  children: React.ReactNode;
  mode: PaletteMode;
}

const CustomThemeProvider = ({ children, mode }: ThemeProviderProps) => {
  const theme = mui(mode);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          height: '100%',
          bgcolor: 'background.default',
          color: 'text.primary',
        }}
      >
        {children}
      </Box>
    </ThemeProvider>
  );
};

export default CustomThemeProvider;
