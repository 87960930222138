

import anime from 'animejs';
import { useState } from 'react';
import { InView } from 'react-intersection-observer';

// @data
import appRoutes from '@/apps/unrealos/core/routes';
import data from '@/apps/unrealos/views/solutions/data';
import { Link } from '@/components/Link';
import IconifyIcon from '@components/IconifyIcon';
import { Box, Button, Typography } from '@mui/material';

export default function Services() {

  // states
  const [open, setOpen] = useState(false);

  const animateCards = () => {
    anime({
      targets: '.service-card',
      translateY: [-100, 0],
      opacity: [0, 1],
      delay: anime.stagger(100),
      easing: 'easeOutQuad',
    })
  }

  const items = open ? data : data.slice(0, 6);

  return (
    <InView
      onChange={(visible: boolean) => {
        if (visible) {
          animateCards()
        }
      }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
      }}>
        <Typography variant="h2" align="center">
          We offer a wide range of services
        </Typography>
        <Box sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(3, 1fr)',
          },
          gap: {
            xs: 10,
            lg: 20,
          },
        }}>
          {items.map((item, index) => {
            return (
              <Box key={index} className="service-card">
                <Box sx={{
                  mb: 2,
                  '& svg': {
                    fontSize: '3rem',
                    color: 'primary.light',
                  }
                }}>
                  {item.icon && (
                    <IconifyIcon icon={item.icon} />
                  )}
                </Box>
                <Box>
                  <Link
                    href={appRoutes.solutions.detail.as({ slug: item.slug })}
                    variant="h5"
                    color="inherit"
                    sx={{
                      mb: 2,
                      whiteSpace: 'pre-line',
                    }}>
                    {item.title}
                  </Link>
                  <Typography variant="body2" color="text.secondary">
                    {item.description}
                  </Typography>
                </Box>
              </Box>
            )
          })}
        </Box>

        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
        }}>
          <Button variant="outlined" onClick={() => setOpen(!open)}>
            {open ? 'Show less' : 'Show more'}
          </Button>
        </Box>
      </Box>

    </InView>
  )
}