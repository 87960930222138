


import config from '@/apps/unrealos/core/config';
import useMedia from '@/hooks/useMedia';
import { Box, Container, Divider, Typography } from '@mui/material';

import VerticalTextScroller from './VerticalTextScroller';
import Video from './Video';

const Header = () => {

    // hooks
    const media = useMedia();

    const isMobile = media.down.md;

    const text = {
        title: 'We Develop',
        description: `We are an official\npartner of Epic Games`,
    }


    if (isMobile) {
        return (
            <Box>
                <Box sx={{
                    px: 5,
                    py: 15,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 10,
                    '& [data-animate]': {
                        justifyContent: 'center',
                    },
                }}>
                    <Box>
                        <Typography variant="h5" align="center">
                            {text.title}
                        </Typography>
                        <VerticalTextScroller />
                    </Box>
                    <Box sx={{
                        width: '100%',
                    }}>
                        <Divider />
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 3,
                    }}>
                        <Box component="img" src={config.assets.logotypes.epicGames} alt="Epic Games" sx={{
                            width: 40,
                            mb: -2,
                            lineHeight: 0,
                        }} />

                        <Typography variant="h6" sx={{
                            whiteSpace: 'pre-line',
                            lineHeight: 1.3,
                        }}>
                            {text.description}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{
                    borderRadius: 3,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    overflow: 'hidden',
                }}>
                    <Video />
                </Box>
            </Box>
        );
    }

    return (
        <Box sx={{
            position: 'relative',
            height: theme => `calc(${theme.mixins.contentHeight} - 100px)`,
        }}>
            <Box sx={{
                pointerEvents: 'none',
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 10,

                display: 'flex',
                alignItems: 'center',
            }}>
                <Container maxWidth="xl">
                    <Box sx={{
                        maxWidth: '60%',
                    }}>
                        <Typography variant="h1">
                            {text.title}
                        </Typography>
                        <VerticalTextScroller />

                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mt: 10,
                            gap: 5,
                        }}>
                            <Box component="img" src={config.assets.logotypes.epicGames} alt="Epic Games" sx={{
                                width: 55,
                                mb: -3,
                                lineHeight: 0,
                            }} />
                            <Typography variant="h5" sx={{
                                whiteSpace: 'pre-line',
                            }}>
                                {text.description}
                            </Typography>
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Video />
        </Box>
    );
}

export default Header;