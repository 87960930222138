import { Link } from '@/components/Link';
import { Box, Container } from '@mui/material';

const Partners = () => {

    const logotypes = [
        {
            name: 'Epic Games',
            url: 'https://www.epicgames.com/',
        },
        {
            name: 'Investors Playbook',
            url: 'https://www.investorsplaybook.com/',
        },
        {
            name: '80.lv',
            url: 'https://80.lv/static-uploads/80lv-Cloud-Gaming-Research-Report-2022.pdf',
        },
        {
            name: 'Amazon AWS',
            url: 'https://aws.amazon.com',
        },
        // {
        //     name: 'KoreanCar',
        //     url: '',
        // },
        // {
        //     name: 'Wixi',
        //     url: 'https://wixi.exchange'
        // },
        // {
        //     name: 'Royal LePage',
        //     url: 'https://www.royallepage.ca/en/investorsedge/developers/14/'
        // },
        {
            name: 'Caribbean MLS',
            url: 'https://caribbeanrealestatemls.com'
        }
    ];

    return (
        <Box sx={{
            position: 'relative',
        }}>
            <Container maxWidth="xl">
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    gap: 10,
                    py: 10,
                }}>
                    {logotypes.map((item, index) => (
                        <Box key={index}>
                            <Link variant="h5"
                                href={item.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                    fontWeight: theme => theme.typography.fontWeightBold,
                                    color: 'text.primary',
                                    textDecoration: 'none',
                                    transition: 'transform 0.3s',
                                    '&:hover': {
                                        transform: 'scale(1.1)',
                                    }
                                }}>
                                {item.name}
                            </Link>
                        </Box>
                    ))}
                </Box>
            </Container>
        </Box>
    )
}

export default Partners;