
import Wave from 'react-wavify';

import Press from '@/apps/unrealos/views/about/Press';
import Wrapper from '@/components/MdxViewer';
import RoundBox from '@/snippets/RoundBox';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';

import MainContent from './content/Main.mdx';
import Header from './Header';
import Partners from './Partners';
import Services from './Services';
import Technologies from './Technologies';

export default function Home() {

    const theme = useTheme();

    return (
        <Box sx={{
            position: 'relative',
        }}>
            <RoundBox variant="top" darken={.1} zIndex={1} bgcolor='background'>
                <Header />
            </RoundBox>
            <Box sx={{
                position: 'relative',
            }}>
                <Wave
                    fill={theme.palette.background.paper}
                    paused={false}
                    style={{
                        pointerEvents: 'none',
                        position: 'absolute',
                        top: -130,
                        left: 0,
                        width: '100%',
                        zIndex: 2,
                    }}
                    options={{
                        height: 110,
                        amplitude: 20,
                        speed: 0.15,
                        points: 10,
                    }}
                />
                <RoundBox variant="bottom" bgcolor='background' darken={.2} sx={{ mt: -3, minHeight: 100 }}>
                    <Partners />
                </RoundBox>
            </Box>

            <Box pt={10}>
                <Box sx={{
                    py: {
                        xs: 10,
                        md: 20,
                    }
                }}>
                    <Container maxWidth="lg">
                        <Wrapper Component={MainContent} />
                    </Container>
                </Box>

                <RoundBox variant="both" bgcolor='background' shift darken={.4} sx={{
                    py: {
                        xs: 10,
                        md: 20,
                    }
                }}>
                    <Container maxWidth="xl">
                        <Technologies />
                    </Container>
                </RoundBox>
            </Box>

            <Box sx={{
                py: {
                    xs: 10,
                    md: 20,
                }
            }}>
                <Container maxWidth="xl">
                    <Services />
                </Container>
            </Box>

            <RoundBox variant="both" bgcolor='background' darken={.2} sx={{
                py: {
                    xs: 10,
                    md: 20,
                }
            }}>
                <Container maxWidth="lg">
                    <Press />
                </Container>
            </RoundBox>

        </Box>
    );
}
