
import { useEffect, useRef, useState } from 'react';
import { InView } from 'react-intersection-observer';
import ReactPlayer from 'react-player';

import config from '@/apps/unrealos/core/config';
import useMedia from '@/hooks/useMedia';
import PlayCircleFilledOutlinedIcon from '@mui/icons-material/PlayCircleFilledOutlined';
import { Box, CircularProgress, darken } from '@mui/material';

export interface VideoControlsState {
  loaded: boolean;
  buffering: boolean;
  url: string;
  preview: string;
}

const VideoBlock = () => {

  // hooks
  const media = useMedia();

  // refs
  const containerRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<ReactPlayer>(null);

  // states
  const [status, setStatus] = useState<VideoControlsState>({
    loaded: false,
    buffering: false,
    url: '',
    preview: '',
  });
  const [active, setActive] = useState(false);
  const [visible, setVisible] = useState(false);

  const updateStatus = (updates: Partial<VideoControlsState>) => {
    setStatus((prev) => ({ ...prev, ...updates }));
  };

  const isMobile = media.down.md;

  useEffect(() => {
    const videoConfig = config.assets.videos.mainVideo;
    const videoSource = isMobile ? videoConfig.mobile : videoConfig.desktop;

    // if (!active) {
    //   playerRef.current?.seekTo(0);
    // }

    setStatus((prev) => {
      const url = (active) ? videoSource.url : videoSource.silent;
      const preview = videoSource.preview;
      return { ...prev, url, preview };
    });
  }, [active, isMobile]);

  useEffect(() => {
    setActive(false);
  }, [isMobile]);

  const handlePlayPause = () => {
    setActive((prev) => !prev);

    // scroll to the video
    // containerRef.current?.scrollIntoView({
    //   behavior: 'smooth',
    //   block: 'start',
    // });
  };

  const renderBuffering = () => {
    if (!status.buffering) return null;

    return (
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1,
        }}
      >
        <CircularProgress size={50} thickness={2} />
      </Box>
    );
  };

  const renderPlayButton = () => {
    if (active || !status.loaded) return null;

    return (
      <Box
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,

          ...isMobile ? {
            left: 0,
            right: 0,
          } : {
            right: '25%',
          },

          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PlayCircleFilledOutlinedIcon sx={{
          fontSize: 60,
          color: 'rgba(255, 255, 255, 0.6)',
        }} />
      </Box>
    );
  }

  const renderGradient = () => {
    // from left to right (darken primary => transparent)
    // (for desktop)

    if (isMobile) return null;

    return (
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: '50%',
          bottom: 0,
          zIndex: 1,
          backgroundImage: theme => `linear-gradient(to right, ${darken(theme.palette.primary.main, 0.5)}, transparent)`,
        }}
      />
    );
  }

  return (
    <InView onChange={(inView) => setVisible(inView)}>
      <Box
        ref={containerRef}
        onClick={handlePlayPause}
        sx={{
          position: "relative",
          cursor: "pointer",
          width: "100%",
        }}
      >

        {renderGradient()}

        <Box sx={{
          pointerEvents: 'none',
          backgroundImage: `url(${status.preview})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: theme => `calc(${theme.mixins.contentHeight} - 100px)`,
          '& video': {
            objectFit: 'cover',
          },
        }}>
          <ReactPlayer
            ref={playerRef}
            url={status.url}
            playing={visible}
            muted={active ? false : true}
            loop={active ? false : true}
            playsinline
            width="100%"
            height="100%"
            onReady={() => updateStatus({ loaded: true })}
            onEnded={() => setActive(false)}
            onBuffer={() => updateStatus({ buffering: true })}
            onBufferEnd={() => updateStatus({ buffering: false })}
          />
        </Box>


        {renderBuffering()}
        {renderPlayButton()}
      </Box>
    </InView>
  );
}

export default () => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) return null;

  return <VideoBlock />;
}