
// @components
import config from '@/apps/unrealos/core/config';
import appRoutes from '@/apps/unrealos/core/routes';
import { ButtonLink } from '@/components/Link';
// blocks
import CustomThemeProvider from '@/theme/ThemeProvider/Custom';
// icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// mui
import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import Forbes from './Forbes';

export default function Press() {

  const logotypes = [
    config.assets.logotypes.lv80,
    config.assets.logotypes.coinTelegraph,
    config.assets.logotypes.forbes,
    config.assets.logotypes.vc,
  ]

  return (
    <Stack spacing={{
      xs: 5,
      md: 15,
    }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        gap: 5,
      }}>
        <Typography variant="h2">
          We are in the press.
        </Typography>
        <ButtonLink
          href={appRoutes.contact.path}
          endIcon={<ArrowForwardIcon />}
          variant="outlined"
          color="inherit">
          Press enquiries
        </ButtonLink>
      </Box>

      <Forbes />

      <Box sx={{
        borderRadius: 2,
        overflow: 'hidden',
      }}>
        <CustomThemeProvider mode="light">
          <Box sx={{
            p: {
              xs: 5,
              md: 10,
            },
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(130px, 1fr))',
            gap: 5,
          }}>
            {logotypes.map((logotype, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <Box
                  component="img"
                  src={logotype}
                  sx={{
                    height: {
                      xs: 35,
                      sm: 80,
                    },
                  }}
                />
              </Box>
            ))}
          </Box>
        </CustomThemeProvider>
      </Box>

    </Stack>
  );
}